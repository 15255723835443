<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <Page>
        <Container ref="container">
            店铺账单
        </Container>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';

export default {
    name: 'ProxyStoreBill',
    mixins: [storeMixin],
    data() {
        return {};
    },
};
</script>

<style lang="scss">
</style>
