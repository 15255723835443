<!-- Created by henian.xu on 2019/9/17. -->

<template>
    <Page>
        <container ref="container">
            <div class="money-box">
                <div class="label">
                    账户余额
                </div>
                <div class="money price">
                    {{ accountBalance }}
                </div>
                <!--<Button
                    to="/distributor/withdraw"
                    theme="w"
                    class="tc-main"
                    radius="all"
                    size="small"
                    label="提取记录"
                />-->
            </div>
            <List>
                <ListItem
                    class="ma-b"
                    label="提现明细"
                />
                <WithdrawItem
                    v-for="item in dataList"
                    :key="item.id"
                    :data="item"
                />
            </List>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
        <PageBar>
            <WithdrawPopup
                :source="2"
                :max-amount="+accountBalance"
                @success="onWithdrawSuccess"
            >
                <Button
                    theme="main"
                    label="提现"
                />
            </WithdrawPopup>
        </PageBar>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import { Buyer } from '@/api';
export default {
    name: 'Collect',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            storeDetail: {},
            dataList: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Sp.ShopStore.getShopStoreDetail()
            .then(json => {
                const res = json.data.data;
                next(vm => {
                    vm.storeDetail = res;
                    console.log(res);
                });
            })
            .catch(() => next());
    },
    computed: {
        accountBalance() {
            const { storeDetail } = this;
            return this.$options.filters.price(
                storeDetail.totalIncome - storeDetail.cashedAmount - storeDetail.cashingAmount,
            );
        },
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.Distributor.distributorWithdrawList({
                withdrawSource: 2,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.dataList = this.dataList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        refresh() {
            this.infiniteDisabled = true;
            this.pagination = {};
            this.dataList = [];
            this.$nextTick(() => (this.infiniteDisabled = false));
        },
        onWithdraw() {
            const self = this;
            const { accountBalance } = this;
            this.$messageBox
                .prompt(`<div class="ma-b">账户余额 <span class="price">${accountBalance}</span></div>`, '提至零钱', {
                    desc: `<div class="tc-red fs-small ma-t">所提交的金额将实时划入您的零钱中，提取到零钱中的金额可用于商品购买。</div>`,
                    placeholder: '请输入要提取到零钱的金额',
                    inputAutoSize: {
                        minRows: 1,
                        maxRows: 1,
                    },
                    validCallback(action, val) {
                        return new Promise((resolve, reject) => {
                            if (action !== 'confirm') return resolve(true);
                            if (isNaN(val)) {
                                reject({
                                    msg: `<div class="tc-red">请输入数值</div>`,
                                });
                                return;
                            }
                            self.$api.Buyer.Sp.ShopStore.shopStoreWithdraw({ withdrawAmount: val })
                                .then(json => {
                                    const res = json.data;
                                    if (res.success) {
                                        self.storeDetail.cashedAmount = +self.storeDetail.cashedAmount + +val;
                                        this.$nextTick(() => {
                                            self.$messageBox.tips(res.msg);
                                        });
                                        self.refresh();
                                        return resolve(true);
                                    }
                                    const obj = {
                                        msg: `<div class="tc-red">${res.msg}</div>`,
                                    };
                                    reject(obj);
                                })
                                .catch(reject);
                        });
                    },
                })
                .then(action => {
                    console.log(action);
                });
        },
        onWithdrawSuccess(val) {
            this.storeDetail.cashedAmount = +this.storeDetail.cashedAmount + +val;
            this.refresh();
        },
    },
};
</script>

<style lang="scss" scoped>
.money-box {
    //background-image: linear-gradient(mix($color-main, $color-yellow, 60%), $color-main);
    background-image: linear-gradient(160deg, #fff -500%, $color-main 70%);
    > .money,
    > .label {
        color: #fff;
    }
}
</style>
