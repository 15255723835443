<!-- Created by henian.xu on 2020/8/13. -->

<template>
    <div class="settlement-item">
        <div class="header">
            <div class="order-sn">
                订单号:{{ data.orderSn }}
            </div>
            <div class="time">
                {{ data.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
            </div>
        </div>
        <div class="body">
            <div class="consignee">
                收件人:{{ data.deliveryConsignee }}
            </div>
            <div class="price">
                {{ data.settlementAmount }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettlementItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.settlement-item {
    background-color: #fff;
    padding: $padding;
    + .settlement-item {
        margin-top: $margin;
    }
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 90%;
        color: $color-text-minor;
    }
    > .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
