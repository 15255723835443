<!-- Created by henian.xu on 2019/10/28. -->

<template>
    <div
        class="upload-customer-service-image"
        @click.stop="onSwitch"
    >
        <slot />
        <!--popup-->
        <transition :name="aniName">
            <Page
                class="bc-w"
                ref="popup"
                v-show="popupShow"
            >
                <Container>
                    <div class="pa-a ta-c">
                        <h3 class="ma-a">
                            请上传, 客服二维码
                        </h3>
                        <upload
                            ref="uploadCustomerServiceImage"
                            class="npa-a kf-qrcode fx-jc-c"
                            action="/rpc/comm/rs/attachment/uploadStoreCustomerServiceImage"
                            accept="image/*"
                            v-model="model"
                            :max-files="1"
                            :column="2"
                        />
                    </div>
                </Container>
                <PageBar>
                    <Button
                        theme="main"
                        @click="$router.go(-1)"
                    >
                        返回
                    </Button>
                </PageBar>
            </Page>
        </transition>
    </div>
</template>

<script>
import popup from '@/mixin/popup';
export default {
    name: 'UploadCustomerServiceImage',
    mixins: [popup],
    data() {
        return {};
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style lang="scss">
.upload-customer-service-image {
}
.upload.kf-qrcode {
    > .item {
        > .inner {
            padding-top: 60%;
            padding-bottom: 60%;
        }

        &.add {
            > .inner {
                //background: transparent url('~@/assets/images/comm/idzhengmian.png') center no-repeat;
                //background-size: contain;
                > .f-icon {
                    //display: none;
                    font-size: 130%;
                }
            }
        }
    }
}
</style>
