<!-- Created by henian.xu on 2020/8/13. -->

<template>
    <Page>
        <Container ref="container">
            <List>
                <SettlementItem
                    v-for="item in listData"
                    :key="item.id"
                    :data="item"
                />
            </List>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </Container>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import infiniteLoading from '@/mixin/infiniteLoading';
import SettlementItem from './_settlementItem';

export default {
    name: 'CumulativeCommission',
    components: { SettlementItem },
    mixins: [storeMixin, infiniteLoading],
    data() {
        return {
            listData: [],
        };
    },
    head() {
        const { settlementStatus } = this;
        const map = {
            20: '累计提现',
            10: '冻结金额',
        };
        const title = map[settlementStatus];
        if (!title) return {};
        return {
            title,
        };
    },
    computed: {
        settlementStatus() {
            return this.$route.params.status || '';
        },
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Sp.ShopStore.orderSettlementList({
                settlementStatus: this.settlementStatus,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.listData = this.listData.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
